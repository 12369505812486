import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

const pStyle = "text-base mb-6"

const PlansSection = ({ agentPage }) => {
  const data = useStaticQuery(graphql`
    query PlansSectionQuery {
      prismicHomePage {
        data {
          sra_plans_section_title
          card_title_1
          card_title_2
          card_title_3
          card_text_1 {
            richText
          }
          card_text_2 {
            richText
          }
          card_text_3 {
            richText
          }
          card_text_agent_1 {
            richText
          }
          card_text_agent_2 {
            richText
          }
          card_text_agent_3 {
            richText
          }
          card_button_1
          card_button_2
          card_button_3
          card_button_link_1 {
            link_type
            uid
            url
          }
          card_button_link_2 {
            link_type
            uid
            url
          }
          card_button_link_3 {
            link_type
            uid
            url
          }
          sra_plans_section_background {
            gatsbyImageData(
              width: 1920
            )
          }
          card_background_image_1 {
            gatsbyImageData(
              width: 640
            )
          }
          card_background_image_2 {
            gatsbyImageData(
              width: 640
            )
          }
          card_background_image_3 {
            gatsbyImageData(
              width: 640
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage
  const sectionBg = getImage(doc.data.sra_plans_section_background)
  const cardBg1 = getImage(doc.data.card_background_image_1)
  const cardBg2 = getImage(doc.data.card_background_image_2)
  const cardBg3 = getImage(doc.data.card_background_image_3)

  return (
    <div id="sra-plans" style={{ display: "grid", backgroundColor: "#1ea0a0", backgroundImage: "linear-gradient(to bottom, #fff, #1ea0a0" }}>
      <GatsbyImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
          minHeight: 700
        }}
        alt="section background"
        image={sectionBg}
        formats={["auto", "webp", "avif"]}
        loading="eager"
        placeholder="none"
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center py-6 xl:py-24 mx-auto my-12 2xl:mt-0 2xl:mb-0">
          <div className="w-10/12 lg:w-1/4 mx-auto border-b-2 border-site-gray-dark pb-5">
            <h2 className="text-site-red text-2xl md:text-4xl uppercase font-extrabold text-center tracking-wider">
              {doc.data.sra_plans_section_title ? doc.data.sra_plans_section_title : "Section Title"}
            </h2>
          </div>

          <div className="mt-12 shadow-md">
            <div className="flex flex-col lg:flex-row item-center">
              <div style={{ display: "grid", backgroundColor: "#1ea0a0" }}>
                <GatsbyImage
                  style={{
                    gridArea: "1/1",
                    objectFit: "cover",
                    opacity: "0.7",
                  }}
                  image={cardBg1}
                  alt="section background"
                  formats={["auto", "webp", "avif"]}
                  loading="eager"
                  placeholder="none"
                />
                <div
                  style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                  }}
                >
                  <h2 className="text-white font-extrabold text-5xl md:text-6xl tracking-wide text-center p-6">
                    {doc.data.card_title_1 ? doc.data.card_title_1 : "Card Title"}
                  </h2>
                </div>
              </div>

              <div className="w-full lg:w-8/12 bg-white p-10 opacity-90">
                {agentPage
                  ?
                  doc.data.card_text_agent_1.richText
                    ?
                    <RichText render={doc.data.card_text_agent_1.richText} htmlSerializer={htmlSerializer} />
                    :
                    <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  :
                  doc.data.card_text_1.richText
                    ?
                    <RichText render={doc.data.card_text_1.richText} htmlSerializer={htmlSerializer} />
                    :
                    <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                }

                <div className="flex flex-col md:flex-row items-center mt-6">
                  {doc.data.card_button_link_1.link_type === "Document" || doc.data.card_button_link_1.link_type === "Any"
                    ?
                    <Link to={doc.data.card_button_link_1.uid ? doc.data.card_button_link_1.uid : "/"}>
                      <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.card_button_1 ? doc.data.card_button_1 : "Button Name"}
                      </button>
                    </Link>
                    :
                    doc.data.card_button_link_1.link_type === "Web" || doc.data.card_button_link_1.link_type === "Media"
                    ?
                    <a
                      href={doc.data.card_button_link_1.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.card_button_1 ? doc.data.card_button_1 : "Button Name"}
                      </button>
                    </a>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="mt-12 shadow-md">
            <div className="flex flex-col-reverse lg:flex-row item-center">
              <div className="w-full lg:w-8/12 bg-white p-10 opacity-90">
                {agentPage
                  ?
                  doc.data.card_text_agent_2.richText
                    ?
                    <RichText render={doc.data.card_text_agent_2.richText} htmlSerializer={htmlSerializer} />
                    :
                    <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  :
                  doc.data.card_text_2.richText
                    ?
                    <RichText render={doc.data.card_text_2.richText} htmlSerializer={htmlSerializer} />
                    :
                    <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                }

                <div className="flex flex-col md:flex-row items-center mt-6">
                  {doc.data.card_button_link_2.link_type === "Document" || doc.data.card_button_link_2.link_type === "Any"
                    ?
                    <Link to={doc.data.card_button_link_2.uid ? doc.data.card_button_link_2.uid : "/"}>
                      <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.card_button_2 ? doc.data.card_button_2 : "Button Name"}
                      </button>
                    </Link>
                    :
                    doc.data.card_button_link_2.link_type === "Web" || doc.data.card_button_link_2.link_type === "Media"
                    ?
                    <a
                      href={doc.data.card_button_link_2.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.card_button_2 ? doc.data.card_button_2 : "Button Name"}
                      </button>
                    </a>
                    :
                    null
                  }
                </div>
              </div>
              <div style={{ display: "grid", backgroundColor: "#1ea0a0" }}>
                <GatsbyImage
                  style={{
                    gridArea: "1/1",
                    objectFit: "cover",
                    opacity: "0.7",
                  }}
                  image={cardBg2}
                  alt="section background"
                  formats={["auto", "webp", "avif"]}
                  loading="eager"
                  placeholder="none"
                />
                <div
                  style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                  }}
                >
                  <h2 className="text-white font-extrabold text-5xl md:text-6xl tracking-wide text-center p-6">
                    {doc.data.card_title_2 ? doc.data.card_title_2 : "Card Title"}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-12 shadow-md">
            <div className="flex flex-col lg:flex-row item-center">
              <div style={{ display: "grid", backgroundColor: "#1ea0a0" }}>
                <GatsbyImage
                  style={{
                    gridArea: "1/1",
                    objectFit: "cover",
                    opacity: "0.7",
                  }}
                  image={cardBg3}
                  alt="section background"
                  formats={["auto", "webp", "avif"]}
                  loading="eager"
                  placeholder="none"
                />
                <div
                  style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                  }}
                >
                  <h2 className="text-white font-extrabold text-5xl md:text-6xl tracking-wide text-center p-6">
                    {doc.data.card_title_3 ? doc.data.card_title_3 : "Card Title"}
                  </h2>
                </div>
              </div>

              <div className="w-full lg:w-8/12 bg-white p-10 opacity-90">
                {agentPage
                  ?
                  doc.data.card_text_agent_3.richText
                    ?
                    <RichText render={doc.data.card_text_agent_3.richText} htmlSerializer={htmlSerializer} />
                    :
                    <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  :
                  doc.data.card_text_3.richText
                    ?
                    <RichText render={doc.data.card_text_3.richText} htmlSerializer={htmlSerializer} />
                    :
                    <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                }

                <div className="flex flex-col md:flex-row items-center mt-6">
                  {doc.data.card_button_link_3.link_type === "Document" || doc.data.card_button_link_3.link_type === "Any"
                    ?
                    <Link to={doc.data.card_button_link_3.uid ? doc.data.card_button_link_3.uid : "/"}>
                      <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.card_button_3 ? doc.data.card_button_3 : "Button Name"}
                      </button>
                    </Link>
                    :
                    doc.data.card_button_link_3.link_type === "Web" || doc.data.card_button_link_3.link_type === "Media"
                    ?
                    <a
                      href={doc.data.card_button_link_3.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                        {doc.data.card_button_3 ? doc.data.card_button_3 : "Button Name"}
                      </button>
                    </a>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PlansSection.propTypes = {
  agentPage: PropTypes.bool,
}

export default PlansSection